import React from "react";
import ContactSection from "./contactSection";
import GallerySection from "./gallerySection";
import LandingSection from "./landingSection";
import MenuSection from "./menuSection";
import KaraokeSection from "./karaokeSection"

export function Homepage(props){
    return (
        <div id="homePage">
            <LandingSection />
            <KaraokeSection />
            <MenuSection />
            <GallerySection />
            <ContactSection />
        </div>
    )
}