
import React from 'react'
import ImageGallery from 'react-image-gallery';

import { render } from "react-dom";
import { ReactPhotoCollage } from 'react-photo-collage'

const setting = {
  width: '100%',
  height: ["400px"],
  layout: [4],
  photos: [  
    {
      source: '/picture/gallery/1.jpg',
    },
    {
      source: '/picture/gallery/2.jpg',
    },
    {
      source: '/picture/gallery/3.jpg',
    },
    {
      source: '/picture/gallery/4.jpg',
    },
  ]
};

export default function KaraokeSection() {
    return (
        <section id="karaokeSection">
            <div className="section">
              <div className="sectionCol jcc aic tac pr30 mb30 karaokeSectionLeft">
              
                <h1>Odkryj Dozo Vegan Ramen</h1>
                <p>Trzech braci, trzech vegan ramen masterów, trzy różne wypracowywane ponad rok receptury, jeden cel - serwowanie pysznych "kluseczek!" Zapraszamy serdecznie do spróbowania ramenów - owoców naszej ciężkiej pracy. </p>
                    
              </div>
              <div className="sectionCol">
                <img src= '/picture/founders.jpg' style={{alignSelf: 'center', maxWidth: '100%'}}  />
                {
                  //<ReactPhotoCollage {...setting} />
                }
                </div>
            </div>
        </section>
    )
}
