
import React from 'react'
import ImageGallery from 'react-image-gallery';

export default function MenuSection() {
    return (
        <section id="menuSection">
            <div className="section">
                <div className="sectionCol" style={{ alignItems: 'center' }}>
                  <a href="picture/menu/menu1_2.jpg" target="_blank">
                    <img style={{width: '100%', maxWidth: '500px', minWidth: '200px'}} src="picture/menu/menu1_2.jpg" alt="menu" />
                  </a>
                </div>
                <div className="sectionCol" style={{ alignItems: 'center' }}>
                  <a href="picture/menu/menu2_2.jpg" target="_blank">
                    <img style={{width: '100%', maxWidth: '500px', minwidth: '200px'}} src="picture/menu/menu2_2.jpg" alt="menu" />
                  </a> 
                  <p>* kliknij aby powiekszyc</p>
                </div>
            </div>
        </section>
    )
}
