
import React from 'react'
import SectionBlock from '../../components/sectionBlock'


export default function ContactSection() {
    return (
        <section id="contactSection">
            <div className="section columnSection">
                <SectionBlock title="Adres" text={["Puławska 132A","Warszawa 02-620"]} />
                <SectionBlock title="Kontakt" text={["kluseczki@dozoveganramen.pl","Tel: +48 690 218 042"]} />
                <SectionBlock title="Godziny Otwarcia" text={
                    [
                        "codziennie: 12:00 - 21:00"
                    ]} />   
            </div>    
        </section>
    )
}
